<template>
  <div class="md:col-span-1 flex gap-4 flex-row md:flex-col">
    <template v-if="isLoading">
      <USkeleton v-for="i in 3" :key="i" class="h-36 w-full" />
    </template>
    <a v-for="item in banners" :key="item.alt" :href="item.url" :target="item.target">
      <NuxtImg
        format="webp"
        loading="lazy"
        :src="item.src"
        width="286"
        height="150"
        class="rounded-lg w-full"
        :alt="item.alt"
      />
    </a>
  </div>
</template>

<script setup lang="ts">
import { TBanner, TBannerOptions, TMarketBannerOptions } from "~/features/banner";

const props = defineProps({
  location: {
    type: String,
    default: "",
    required: true,
  },
});

const isLoading = ref(true);
const cookieMarket = useCookie("market");

const config = useRuntimeConfig();

const banners = ref<TBannerOptions[]>([]);

onMounted(async () => {
  try {
    isLoading.value = true;
    const result: TBanner = await $fetch(
      `${config.public.apiBase}/api/banners?location=${props.location}`
    );

    const bannerByMarket = JSON.parse(result.options) as TMarketBannerOptions;
    banners.value =
      bannerByMarket[cookieMarket?.value ?? "vietnam"] ?? bannerByMarket["vietnam"] ?? [];
  } catch (e) {
    console.error(e);
    banners.value = [];
  } finally {
    isLoading.value = false;
  }
});
</script>
